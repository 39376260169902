<script>
  export let checkoutLink
</script>

<div class="bg-[#0D231D] shadow-lg py-3 text-center">
  <a
    href={checkoutLink}
    class="text-white text-center text-base md:text-2xl font-semibold underline"
  >
    UPDATE - FIXD Flash Sale going on now - save up to 67% today!
  </a>
</div>
<div class="w-full h-4 bg-[#3F5C55] mb-3" />
<div class="w-full h-4 bg-[#0A8E51]" />
