<script>
  import Carousel from '@/components/Carousel.svelte'
  import CarouselItem from '@/components/CarouselItem.svelte'
  import { StarFilledRound, Guard } from '@/components/icons'

  const cards = [
    {
      title: 'FIXD is a straightforward deep scanning auto tool ',
      description:
        "After unpacking and downloading the app, I plugged it into my vehicle and the process began. FIXD was ready to get my car fixed!!! The instructions are very easy to follow. The FIXD product/tool is a vehicle owner's dream. The information that's offered, the assistance and help that is recommended is phenomenal!!!",
      name: 'Dona',

      date: 'November 22, 2023',
    },
    {
      title: 'Well worth the money!',
      description:
        "I got this right after my service light came on. I was able to diagnose the problem and tell the mechanic what's wrong. I got the car fixed and feel better about driving the car. Not worried about the cost, because the app tells me everything.",
      date: 'November 9, 2023',
    },
    {
      title: 'Great peace of mind!',
      description:
        'Had a check engine light come on during a trip, this device told us that it was not a critical issue and would not affect the performance of the car. BIG relief to know the car was not going to break down on our trip. Allowed us to tell the repair show exactly what the issue was when we had time. A real life saver!',
      name: 'Ra B.',

      date: 'May 31, 2023',
    },
    {
      title: 'Bought one as a gift for my son.',
      description:
        'This is perfect for the homeowner/ mechanic who wants to know more about the condition of the vehicle and the status of service lights. Works well with apple and great source of knowledge for codes.',
      name: 'Carlton M.',
      date: 'December 10, 2023',
    },
  ]
</script>

<Carousel
  showPagination={false}
  showArrows={true}
  splideOptions={{
    perPage: 3,
    gap: 20,
    breakpoints: {
      768: {
        perPage: 1,
      },
    },
  }}
>
  {#each cards as item}
    <CarouselItem>
      <div class="p-6 md:p-8 border rounded-3xl text-center min-h-[458px]">
        <div class="flex items-center justify-between flex-col min-h-[410px]">
          <div class="space-y-6">
            <div class="flex items-center gap-1 justify-center">
              {#each new Array(5) as _}
                <StarFilledRound color="yellow" class="size-5 md:size-8" />
              {/each}
            </div>
            <p class="text-lg md:text-xl font-bold">
              {item.title}
            </p>
            <p class="text-15 md:text-17">
              {item.description}
            </p>
          </div>
          <div class="flex items-center gap-3 justify-center">
            <Guard color="green" />
            <p class="text-grey text-xs md:text-15">
              Verified Purchase - {item.date}
            </p>
          </div>
        </div>
      </div>
    </CarouselItem>
  {/each}
</Carousel>
