<script>
  import HeroImage from '@/images/landing-page/scorpio/ttr/banner_bfcm.png'
  import Section from '@/components/landing-page/widgets/Section.svelte'
  import { onMount } from 'svelte'

  import PCMag from '@/images/landing-page/pc-mag.png'
  import FoxImage from '@/images/landing-page/fox-news.png'
  import UsaToday from '@/images/landing-page/usa-today.png'
  import EngadgetImage from '@/images/landing-page/engadget.png'
  import BusinessInsiderImage from '@/images/landing-page/business-insider.png'
  import LiveReviews from '@/components/landing-page/LiveReviews.svelte'

  import FAQ from '@/pages/scorpio/components/L017/FAQ.svelte'

  import Banner from './components/Banner.svelte'

  import PlugInVideo from '@/images/landing-page/scorpio/plug-in-play.mp4'
  import LogoImage from '@/images/landing-page/scorpio/ttr-logo.png'

  import { Checkmark, CloseRound } from '@/components/icons'

  export let checkoutLink
  export let onClick

  onMount(() => {
    document.querySelectorAll('a[href^="#"]').forEach((anchor) => {
      anchor.addEventListener('click', function (e) {
        if (this.getAttribute('href').includes('#')) {
          e.preventDefault()

          document.querySelector(this.getAttribute('href')).scrollIntoView({
            behavior: 'smooth',
          })
        }
      })
    })
  })

  const date = new Date().toLocaleDateString('en-US', {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  })
</script>

<svelte:head>
  <!-- preload all the image assets to avoid animation flicker -->
  <link rel="preload" href={HeroImage} as="image" />
  <link rel="preload" href={LogoImage} as="image" />
  <link rel="preload" href={BusinessInsiderImage} as="image" />
  <link rel="preload" href={PCMag} as="image" />
  <link rel="preload" href={UsaToday} as="image" />
  <link rel="preload" href={EngadgetImage} as="image" />
  <link rel="preload" href={FoxImage} as="image" />
</svelte:head>

<main class="font-montserrat relative">
  <div class="my-2">
    <p class="text-center font-inter text-sm italic text-grey">Advertorial</p>
  </div>
  <Banner {checkoutLink} />

  <div class="max-w-[940px] mx-auto text-center py-6 md:py-10">
    <img src={LogoImage} alt="atr-logo" class="w-3/4 sm:w-5/12" />
  </div>

  <div class="relative">
    <Section horizontalPadding="none" verticalPadding="none">
      <header class="mb-7 px-2.5 md:px-0">
        <h1 class="text-2xl md:text-40 font-bold">
          America's #1 Car Tool That Will Sell Out this November 2024
        </h1>
      </header>
      <article>
        <div class="px-2 mb-5">
          <p class="text-sm">{date}</p>
        </div>

        <div class="mx-auto w-full text-center">
          <img src={HeroImage} alt="100% money-back guarantee" />
        </div>
        <div class="my-5 xs:py-0 text-center">
          <div class="hidden md:block">
            <h3 class="text-2xl md:text-4xl font-bold">
              Over 50,000 ⭐⭐⭐⭐⭐ 5-Star Reviews and Counting
            </h3>
          </div>
          <div class="md:hidden">
            <h3 class="text-2xl md:text-4xl font-bold mb-4">⭐⭐⭐⭐⭐</h3>
            <h3 class="text-2xl md:text-4xl font-bold">
              Over 50,000 <br /> 5-Star Reviews and Counting
            </h3>
          </div>
        </div>
      </article>
    </Section>
    <Section verticalPadding="none">
      <article class="space-y-5 text-sm text-xl md:text-2xl leading-tight">
        <p>Hey, listen…</p>

        <p>
          You and I both know how much of a pain in the rear car problems can
          be.
        </p>

        <p>
          You're driving along, minding your own business, and then - BAM! -
          that dreaded check engine light comes on. Your heart skips a beat. You
          break into a cold sweat, wondering how much the mechanic's gonna drain
          your wallet this time.
        </p>

        <p>
          You don't know if it's a $50 easy fix or you're about to take out a
          payday loan just to keep your car on the road.
        </p>

        <p>But what if…</p>

        <p>
          What if you could know exactly what's wrong BEFORE you hand over your
          hard-earned cash to the shop?
        </p>

        <p>
          What if diagnosing car problems was as easy as tapping a button on
          your phone?
        </p>

        <p>
          What if you could fix some of those pesky problems yourself and save
          enough money to take your family on vacation instead of dumping it all
          into your car??
        </p>

        <p>
          Well, buckle up, because I'm about to introduce you to a little gadget
          that does exactly that.
        </p>
      </article>

      <div class="my-5 md:my- md:pl-2">
        <h2 class="text-xl md:text-2xl">
          The Secret Tool That Mechanics Don't Want You to Know About
        </h2>
      </div>

      <article class="space-y-5 text-sm text-xl md:text-2xl leading-tight">
        <p>Picture this:</p>

        <p>
          You plug a small device into your car (it takes 10 seconds), and in
          less time than it takes to drink your morning coffee, it tells you
          exactly what's wrong with your car.
        </p>

        <p>No guesswork. No B.S.</p>

        <p>Sounds like a dream, right?</p>

        <p>
          That's what FIXD does. It's a pocket-sized OBD2 scanner that plugs
          into your car and gives you all the dirty details. It's like having
          your own personal mechanic riding shotgun, 24/7.
        </p>

        <p>Let me tell you 5 reasons why you need this little sucker TODAY.</p>
      </article>

      <article class="space-y-5 text-sm text-xl md:text-2xl mt-8 leading-tight">
        <h2 class="text-xl md:text-2xl">
          Reason #1: Fix Your Car Without Getting Ripped Off
        </h2>

        <p>
          Ever felt like your mechanic was speaking a different language just to
          confuse you into paying more? I've been there. But with FIXD, you get
          a crystal-clear diagnosis of what's wrong with your car BEFORE you go
          to the shop.
        </p>

        <p>
          No more shady upcharges. No more paying for repairs you don't need.
          FIXD puts the power back in your hands. It tells you if the problem is
          something minor, like a loose gas cap, or if it's something serious
          that needs a pro. Either way, you know what you're dealing with, and
          the mechanic can't take you for a ride.
        </p>

        <h2 class="text-xl md:text-2xl">
          Reason #2: You'll Never Pay for Diagnostics Again
        </h2>

        <p>
          Mechanics love charging you $100+ just to tell you what's wrong,
          before they even lift a wrench. Why pay them when you can figure it
          out yourself in less than a minute?
        </p>

        <p>
          With <a href={checkoutLink} on:click|preventDefault={onClick}>FIXD</a
          >, you plug it in, fire up the app on your phone, and boom—you're
          instantly smarter than most mechanics. Even if you end up needing a
          repair, you're walking into the shop knowing exactly what needs
          fixing.
        </p>

        <p>
          The best part? You just saved yourself that $100 diagnostic fee.
          Imagine what you could do with an extra $100 every time something went
          wrong with your car.
        </p>

        <h2 class="text-xl md:text-2xl">
          Reason #3: Get Real-Time Info as You Drive
        </h2>

        <p>
          Here's where the magic happens. FIXD doesn't just tell you what's
          wrong—it gives you real-time data while you drive. You can monitor
          things like fuel efficiency, engine temp, and oxygen sensors in
          real-time.
        </p>

        <p>What does this mean for you?</p>

        <p>
          It means you can catch problems before they even start. It means
          fine-tuning your car for better performance. And if you're into speed
          or fuel efficiency, you can use this data to optimize your ride like a
          pro.
        </p>

        <h2 class="text-xl md:text-2xl">
          Reason #4: End the Mystery of the Check Engine Light
        </h2>

        <p>
          You know that sinking feeling when the check engine light comes on?
          It's like a ticking time bomb—will it blow up your engine, or is it
          just a loose gas cap?
        </p>

        <p>
          With <a href={checkoutLink} on:click|preventDefault={onClick}>FIXD</a
          >, there's no more guessing. You plug it in, and in seconds, you'll
          know exactly why that light's on. FIXD pulls up the code and
          translates it into plain English, so you know what the deal is and how
          to handle it.
        </p>

        <h2 class="text-xl md:text-2xl">
          Reason #5: It's the Smartest Tool for Car Owners. Period.
        </h2>

        <p>
          Let me tell you something… FIXD is the most advanced, easy-to-use OBD
          scanner out there. This little device taps into your car's brain (the
          ECU), uncovers hidden problems, and shows you every fault, error, and
          malfunction under the hood.
        </p>

        <p>
          It works on ANY car built after 1996, so whether you're driving a
          brand-new SUV or a fixer-upper, FIXD has your back.
        </p>

        <p>
          But here's the kicker—you don't need to be a mechanic to use it. The <a
            href={checkoutLink}
            on:click|preventDefault={onClick}>free app</a
          > on your phone makes it simple, and you'll have all the answers in seconds.
        </p>

        <p>
          It's just like your mechanic's bulky, expensive pro scanner, but gives
          you much more:
        </p>
      </article>

      <div class="text-center my-20">
        <h1 class="text-30 md:text-[60px] mb-6 md:mb-10">
          The <span class="text-green">FIXD</span> Difference!
        </h1>
        <p class="font-bold text-lg md:text-xl">
          It works just like your mechanic's bulky, expensive pro scanner,
          <br class="hidden md:block" />
          <span class="text-green">but gives you much more:</span>
        </p>
      </div>
    </Section>

    <Section verticalPadding="none" maxWidth="xl">
      <article class="space-y-4 text-sm leading-5">
        <div class="flex flex-col lg:flex-row">
          <div class="flex-1">
            <header
              class="flex items-center justify-center py-4 w-1/2 mx-auto bg-green rounded-t-3xl"
            >
              <h4 class="text-white text-17">FIXD PERK</h4>
            </header>
            <div
              class="px-4 py-5 md:p-13 space-y-6 border rounded-2xl lg:rounded-s-[3rem] lg:rounded-e-none lg:border-r-0"
            >
              <div class="flex gap-4 items-center">
                <div
                  class="size-6 bg-green rounded flex items-center justify-center"
                >
                  <Checkmark color="white" />
                </div>
                <p class="text-15 md:text-[17px]">
                  Works on all gas cars made after 1995
                </p>
              </div>
              <div class="flex gap-4 items-center">
                <div
                  class="size-6 bg-green rounded flex items-center justify-center"
                >
                  <Checkmark color="white" />
                </div>
                <p class="text-15 md:text-[17px]">
                  Clear your check engine light (with 1 tap)
                </p>
              </div>
              <div class="flex gap-4 items-center">
                <div
                  class="size-6 bg-green rounded flex items-center justify-center"
                >
                  <Checkmark color="white" />
                </div>
                <p class="text-15 md:text-[17px]">
                  Accurately diagnoses 7,000+ car problems
                </p>
              </div>
              <div class="flex gap-4 items-center">
                <div
                  class="size-6 bg-green rounded flex items-center justify-center"
                >
                  <Checkmark color="white" />
                </div>
                <p class="text-15 md:text-[17px]">
                  Explains car problems in simple terms
                </p>
              </div>
              <div class="flex gap-4 items-center">
                <div
                  class="size-6 bg-green rounded flex items-center justify-center"
                >
                  <Checkmark color="white" />
                </div>
                <p class="text-15 md:text-[17px]">
                  Gives you an accurate repair cost estimate using AI, so you
                  never overpay
                </p>
              </div>
              <div class="flex gap-4 items-center">
                <div
                  class="size-6 bg-green rounded flex items-center justify-center"
                >
                  <Checkmark color="white" />
                </div>
                <p class="text-15 md:text-[17px]">
                  Gives you access to expert live mechanics to help with repairs
                  and questions right now.
                </p>
              </div>
              <div class="flex gap-4 items-center">
                <div
                  class="size-6 bg-green rounded flex items-center justify-center"
                >
                  <Checkmark color="white" />
                </div>
                <p class="text-15 md:text-[17px]">
                  Maintenance reminders to help you avoid unneeded repairs
                </p>
              </div>
              <div class="flex gap-4 items-center">
                <div
                  class="size-6 bg-green rounded flex items-center justify-center"
                >
                  <Checkmark color="white" />
                </div>
                <p class="text-15 md:text-[17px]">
                  Predict future issues before they happen
                </p>
              </div>

              <div class="text-center">
                <a
                  href={checkoutLink}
                  on:click|preventDefault={onClick}
                  class="bg-green px-6 py-3 text-white uppercase rounded-full no-underline font-bold text-17 inline-block"
                >
                  get up to 67% off fixd
                </a>
              </div>
            </div>
          </div>

          <div class="flex-1 pt-11 sm:pt-0">
            <header
              class="flex items-center justify-center py-4 w-1/2 mx-auto bg-black rounded-t-3xl"
            >
              <h4 class="text-white text-center text-17">
                Mechanic's Pro Scanner
              </h4>
            </header>
            <div
              class="px-4 py-5 md:p-13 space-y-6 border rounded-2xl lg:rounded-e-[3rem] lg:rounded-l-none"
            >
              <div class="flex gap-4 items-center">
                <div
                  class="size-6 bg-black rounded flex items-center justify-center"
                >
                  <Checkmark color="white" />
                </div>
                <p class="text-15 md:text-[17px]">
                  Works on all gas cars made after 1995
                </p>
              </div>
              <div class="flex gap-4 items-center">
                <div
                  class="size-6 bg-black rounded flex items-center justify-center"
                >
                  <Checkmark color="white" />
                </div>
                <p class="text-15 md:text-[17px]">
                  Clear your check engine light
                </p>
              </div>
              <div class="flex gap-4 items-center">
                <div
                  class="size-6 bg-black rounded flex items-center justify-center"
                >
                  <Checkmark color="white" />
                </div>
                <p class="text-15 md:text-[17px]">
                  Accurately diagnoses over 7,000 common car problems
                </p>
              </div>
              <div class="flex gap-4 items-center">
                <div
                  class="size-6 bg-red shrink-0 rounded flex items-center justify-center"
                >
                  <CloseRound color="white" size="sm" />
                </div>
                <p class="text-15 md:text-[17px]">
                  Have to interpret confusing error codes
                </p>
              </div>
              <div class="flex gap-4 items-center">
                <div
                  class="size-6 bg-red shrink-0 rounded flex items-center justify-center"
                >
                  <CloseRound color="white" size="sm" />
                </div>
                <p class="text-15 md:text-[17px]">
                  Mechanics will still charge you up to $300 to diagnose your
                  check engine light
                </p>
              </div>
              <div class="flex gap-4 items-center">
                <div
                  class="size-6 bg-red shrink-0 rounded flex items-center justify-center"
                >
                  <CloseRound color="white" size="sm" />
                </div>
                <p class="text-15 md:text-[17px]">
                  You have to go to the car repair shop, where your mechanic
                  could try to rip you off
                </p>
              </div>
              <div class="flex gap-4 items-center">
                <div
                  class="size-6 bg-red shrink-0 rounded flex items-center justify-center"
                >
                  <CloseRound color="white" size="sm" />
                </div>
                <p class="text-15 md:text-[17px]">
                  No support for maintenance reminders
                </p>
              </div>
              <div class="flex gap-4 items-center">
                <div
                  class="size-6 bg-red shrink-0 rounded flex items-center justify-center"
                >
                  <CloseRound color="white" size="sm" />
                </div>
                <p class="text-15 md:text-[17px]">Only shows current issues</p>
              </div>

              <div class="text-center">
                <p
                  class="bg-red px-6 py-3 text-white uppercase rounded-full no-underline font-bold text-17 inline-block"
                >
                  COST: OVER $2,000
                </p>
              </div>
            </div>
          </div>
        </div>
      </article>
    </Section>

    <Section>
      <h2 class="font-bold text-2xl md:text-40 text-center mb-6 md:mb-10">
        How does FIXD work?
      </h2>
      <article class="flex flex-col-reverse md:flex-row">
        <div class="flex-1 mt-5 md:mt-12">
          <div class="space-y-6 text-xl md:text-2xl leading-tight">
            <p>
              FIXD is easy to use! There are just three simple steps to get
              started:
            </p>
            <p>Step 1: Plug your FIXD scanner into your car.</p>
            <p>
              Step 2: Open the FIXD app on your Android or Apple smartphone.
            </p>
            <p>Step 3: Click the green “Tap to Scan” button.</p>
          </div>
        </div>
        <div class="flex-1 mt-3 xs:mt-0">
          <video
            src={PlugInVideo}
            alt="plug in play"
            autoplay
            loop
            muted
            type="video/mp4"
            height="auto"
            class="rounded-[3rem] w-full xs:w-[424px] h-[550px] object-cover object-center mx-auto md:ml-auto"
          />
        </div>
      </article>

      <article class="space-y-6 text-sm text-xl md:text-2xl mt-8 leading-tight">
        <p>
          Ever felt like your mechanic was speaking a different language just to
          confuse you into paying more? I've been there. But with FIXD, you get
          a crystal-clear diagnosis of what's wrong with your car BEFORE you go
          to the shop.
        </p>

        <p>So, How Much Could You Save?</p>

        <p>
          Look, you've probably spent hundreds—if not thousands—on car repairs
          in the past. And you'll probably spend a lot more in the future. But
          with FIXD, you're cutting those costs down to size.
        </p>

        <p>
          You won't get overcharged for diagnostics. You won't pay for
          unnecessary repairs. You won't drive blind wondering what's wrong.
        </p>

        <p>
          For just a tiny investment, FIXD pays for itself the first time you
          use it. Heck, even if you only save $100 on your first diagnostic,
          that's a win, right? But most people save WAY more than that.
        </p>

        <div class="text-center">
          <a
            href={checkoutLink}
            on:click|preventDefault={onClick}
            class="bg-[#3F5C55] w-full md:w-5/12 py-8 md:py-10 text-white uppercase rounded-full no-underline font-bold text-2xl inline-block"
          >
            SEE SALE PRICE
          </a>
        </div>

        <p>Here's What You Need to Do Next…</p>

        <p>
          Go to the <a href={checkoutLink} on:click|preventDefault={onClick}
            >FIXD website right now</a
          >. Grab one (or two, if you have a second car) while they're still on
          sale. I don't know how long they'll keep this deal running, so don't
          sit on this. Trust me, you'll kick yourself if you miss out.
        </p>

        <p>
          Once your FIXD arrives, plug it into your car, fire up the app, and
          take control of your car's health. No more surprises. No more stress.
          Just peace of mind.
        </p>

        <p>
          And the next time that check engine light comes on, you'll be the one
          calling the shots.
        </p>

        <p>
          FIXD is 100% free to use forever once you have the device. And over
          100k people have opted-in to the optional <a
            href={checkoutLink}
            on:click|preventDefault={onClick}>FIXD Premium</a
          > membership for around $8/month, which gives you access to powerful features,
          including a Mechanic Hotline and repair cost estimates. That's a bargain
          considering pro scan tools cost thousands of dollars.
        </p>

        <p>
          If you sign up through <a
            href={checkoutLink}
            on:click|preventDefault={onClick}>their discount page</a
          >, you'll get access to their no-commitment, free-trial so you can
          test drive all the extra features at no additional cost.
        </p>

        <p>
          I bought some for my parents and kids for the holidays. So if there's
          any issues with their cars, I can see that right from my phone! They
          love it too and I also love the peace of mind I get knowing they're
          safe.
        </p>

        <p>
          <a href={checkoutLink} on:click|preventDefault={onClick}>FIXD</a> even
          comes with a FREE 1-year warranty and a 30-day money-back guarantee, so
          there's absolutely no risk to you. You can always return it.
        </p>
      </article>

      <article>
        <div class="my-10 xs:py-0 text-center">
          <div class="hidden md:block">
            <h3 class="text-2xl md:text-4xl font-bold">
              Over 50,000 ⭐⭐⭐⭐⭐ 5-Star Reviews and Counting
            </h3>
          </div>
          <div class="md:hidden">
            <h3 class="text-2xl md:text-4xl font-bold mb-4">⭐⭐⭐⭐⭐</h3>
            <h3 class="text-2xl md:text-4xl font-bold">
              Over 50,000 <br /> 5-Star Reviews and Counting
            </h3>
          </div>
        </div>
      </article>
      <article class="hidden md:block">
        <div
          class="flex flex-wrap md:grid grid-row-2 grid-cols-3 xs:grid-cols-5 gap-4 items-center justify-center py-[2%]"
        >
          <div class="text-center">
            <img src={PCMag} alt="PC Magazine" class="max-h-13 md:max-h-20" />
          </div>
          <div class="text-center">
            <img src={FoxImage} alt="Fox News" class="max-h-13 md:max-h-20" />
          </div>
          <div class="text-center">
            <img src={UsaToday} alt="Usa Today" class="max-h-13 md:max-h-20" />
          </div>
          <div class="text-center">
            <img
              src={EngadgetImage}
              alt="business insider"
              class="max-h-13 md:max-h-20"
            />
          </div>
          <div class="text-center">
            <img
              src={BusinessInsiderImage}
              alt="business insider"
              class="max-h-13 md:max-h-20"
            />
          </div>
        </div>
      </article>
    </Section>

    <Section horizontalPadding="none">
      <div class="bg-[#F6F6F6] pb-15">
        <div class="h-5 bg-[#3F5C55]"></div>

        <div class="text-center pb-6 pt-10">
          <h1 class="mb-4">FIXD FLASH SALE!</h1>
          <h1>Save up to 67% now!</h1>
        </div>
        <div class="text-center">
          <a
            href={checkoutLink}
            on:click|preventDefault={onClick}
            class="bg-[#3F5C55] w-full md:w-5/12 py-8 md:py-10 text-white uppercase rounded-full no-underline font-bold text-2xl inline-block"
          >
            SEE SALE PRICE
          </a>
        </div>
      </div>
    </Section>

    <Section verticalPadding="none">
      <LiveReviews backgroundColor="transparent" />
    </Section>

    <div class="text-center hidden md:block">
      <a
        href={checkoutLink}
        on:click|preventDefault={onClick}
        class="bg-[#3F5C55] w-full md:w-3/12 py-8 md:py-10 text-white uppercase rounded-full no-underline font-bold text-2xl inline-block"
      >
        CLAIM MY SAVINGS
      </a>
    </div>
  </div>

  <div class="mt-14">
    <FAQ />
  </div>

  <div class="w-full bg-[#0d232d] h-[100px]" />
</main>
