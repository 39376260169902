<script>
  import { writable } from 'svelte/store'
  import { slide } from 'svelte/transition'

  import { CloseRound, Plus } from '@/components/icons'

  export let open = false

  const isOpen = writable(open)

  function toggleAccordion() {
    isOpen.update((value) => !value)
  }
</script>

<style>
  .open {
    max-height: 3000px;
  }
</style>

<div class="p-6 border rounded-2xl w-full">
  <!-- svelte-ignore a11y-no-static-element-interactions -->
  <div
    class="cursor-pointer"
    on:click={toggleAccordion}
    on:keypress={toggleAccordion}
  >
    <div class="flex items-center justify-between gap-2">
      <slot name="header" />
      <div
        class="shrink-0 size-6 bg-green flex items-center justify-center rounded-lg"
      >
        {#if $isOpen}
          <CloseRound color="white" size="xs" />
        {:else}
          <Plus color="white" />
        {/if}
      </div>
    </div>
    {#if $isOpen}
      <div
        class="accordion-body overflow-hidden max-h-0 text-left mt-2"
        class:open={$isOpen}
        transition:slide
      >
        <slot name="body" />
      </div>
    {/if}
  </div>
</div>
